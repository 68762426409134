<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("authorizeDocument.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
          class="btn btn-color btn-border btn-border-color"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search.search')"
          v-model="searchItem"
          @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("authorizeDocument.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="authorizeDocument.length > 0">
          <thead>
            <tr>
              <th class="text-start">
                {{ $t("authorizeDocument.no") }}
              </th>
              <th class="text-start">
                {{ $t("authorizeDocument.emp_number") }}
              </th>
              <th class="text-start">
                {{ $t("authorizeDocument.image") }}
              </th>
              <th class="text-left">
                {{ $t("authorizeDocument.userName") }}
              </th>
              <th class="text-left">
                {{ $t("authorizeDocument.dept") }}
              </th>
              <th class="text-left">
                {{ $t("authorizeDocument.position") }}
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in authorizeDocument" :key="idx">
              <td>
                {{ pagination.current_page * 10 - 10 + idx + 1 }}
              </td>
              <td>{{ item.employee_number }}</td>

              <td>
                <div class="img-table-company" v-if="item.employee_path">
                  <img :src="item.employee_path" alt="" />
                </div>
                <div class="demo-profile" v-else>
                  <i class="fal fa-user-alt"></i>
                </div>
              </td>
              <td>{{ item.employee_name }} {{ item.employee_surname }}</td>
              <td>{{ item.department.name }}</td>
              <td>
                <p v-for="(position, idx) in item.position" :key="idx">
                  {{ position.name }}
                </p>
              </td>
              <td></td>

              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <!--                  <v-list-item>-->
                    <!--                    <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>-->
                    <!--                  </v-list-item>-->
                    <v-list-item>
                      <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />
        <Pagination
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchUserAuthorizeDocument"
        >
        </Pagination>

        <!--        <ModalEdit fullscreen title="ແກ້ໄຂຂໍ້ມູນ">-->
        <!--          <template v-slot="{close}">-->
        <!--            <EditAuthorizeDocument :item="authorizeDocumentItem" @close="close" @success="fetchUserAuthorizeDocument"/>-->
        <!--          </template>-->
        <!--        </ModalEdit>-->

        <ModalDelete>
          <template v-slot="{ close }">
            <Delete
              :authorizeDocumentId="authorizeDocumentId"
              @close="close"
              @success="fetchUserAuthorizeDocument"
            />
          </template>
        </ModalDelete>

        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import Loading from "../../../components/Loading";
// import EditAuthorizeDocument from "@/views/MainCompany/AuthorizeDocumentUser/Edit"
import Delete from "@/views/MainCompany/AuthorizeDocumentUser/Delete";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    // EditAuthorizeDocument,
    Delete,
  },

  data() {
    return {
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: false,
      searchItem: "",
      authorizeDocument: [],
      authorizeDocumentItem: {},
      authorizeDocumentId: "",
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchUserAuthorizeDocument();
    },
    onCreate() {
      this.$router.push({ name: "authorize.document.create" }).catch(() => {});
    },

    filterAuthorizeDocument(authorizeDocumentId) {
      return (
        this.authorizeDocument.filter((item) => {
          return item.id == authorizeDocumentId;
        })[0] || {}
      );
    },

    onEdit(authorizeDocumentId) {
      this.authorizeDocumentItem = {
        ...this.filterAuthorizeDocument(authorizeDocumentId),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(authorizeDocumentId) {
      this.authorizeDocumentId = authorizeDocumentId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchUserAuthorizeDocument() {
      this.isLoading = true;
      this.$axios
        .get(`company/list/document/authorized/users`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            this.isLoading = false;
            this.authorizeDocument = res.data.data.data;
            console.log(this.authorizeDocument);
            this.pagination = res.data.data.pagination;
            if (!this.authorizeDocument.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch(() => {});
    },
  },
  created() {
    this.fetchUserAuthorizeDocument();
  },
};
</script>

<style scoped lang="scss"></style>
